import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Container from "react-bootstrap/Container"
import { PageLayout, PageTitle } from "../components"
import SEO from "../utils/seo"

export default ({ title, excerpt, html, subTitle, childreen }) => (
  <PageLayout pageName="post-template">
    <SEO title={title} description={excerpt} />

    {/* <a
      onClick={() => window.history.back()}
      class="arrow arrow-bar is-left"
    ></a> */}

    <FontAwesomeIcon
      size="2x"
      icon={["fas", "arrow-left"]}
      className="icons github"
      title="Retour"
      onClick={() => window.history.back()}
    />

    <Container className="text-center" fluid>
      <PageTitle title={title} />
      {subTitle}
      <Container className="text-justify">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
      {childreen}
    </Container>
  </PageLayout>
)
