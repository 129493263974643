import { graphql } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"
import React from "react"
import PostTemplate from "./post-template"

const SubTitle = ({ ttr, date, author }) => (
  <h5 className="text-muted mb-5">
    Temps de Lecture: {ttr} <small>min</small> | {date} | {author}
  </h5>
)

export default ({ data, location, config }) => {
  const post = data.markdownRemark
  const { siteUrl } = data.site.siteMetadata

  let disqusConfig = {
    url: `${siteUrl + location.pathname}`,
    identifier: post.frontmatter.id,
    title: post.frontmatter.title,
  }

  return (
    <>
      <PostTemplate
        title={post.frontmatter.title}
        subTitle={
          <SubTitle
            ttr={post.timeToRead}
            date={post.frontmatter.date}
            author={post.frontmatter.author}
          />
        }
        excerpt={post.excerpt}
        html={post.html}
        childreen={<Disqus config={disqusConfig} />}
      />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        id
        title
        author
        date(formatString: "DD MMMM, YYYY")
      }
      excerpt
      timeToRead
    }
  }
`
